import React, {useEffect, useContext, useState} from "react";
import {connect} from "react-redux";
import {
    addClient,
    editClient
} from "@actions/clientsActions";
import {
    getCurrencyList
} from "@actions/currency"
import {
    REQUEST_ADD_CLIENT,
    REQUEST_EDIT_CLIENT
} from "@actions/actionTypes";
import {Modal, Form, Input, Row, Col, Switch, Radio, Button, Space, Tabs, Select, Tooltip} from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import classNames from "classnames";
import ModalLoader from "@components/Loaders/modalLoader";
import Infinite from "react-infinite";
import iconDown from "@assets/img/icon/iconDown.svg";
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {
    ResponseContext
} from "../../hooks/ResponseObserver";

const defaultCurrencies =  [
    {
        "minStake": undefined,
        "currencyId": undefined,
        "maxStake": undefined,
    }
]


function ClientControlPopup({handleCancel, show, intl, addClient, client, editClient, loading, getCurrencyList, currencyList}) {
    const [form] = Form.useForm();
    const [tabMode, setTabMode] = useState("1");
    const [firstTabError, setFirstTabError] = useState(false);
    const [secondTabValid, setSecondTabValid] = useState(false);
    const [isFirstOpen, setIsFirstOpen] = useState(!client?.id);
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);

    const handleModeChange = (e) => {
        const mode = e.target.value;
        if (mode === "2") {
            setIsFirstOpen(false)
        }
        setTabMode(mode);
    }

    useEffect(() => {
        if (client.id) {
            const formBody = JSON.parse(JSON.stringify(client))

            if (!(client.currencies && client.currencies[0])) {
                formBody.currencies = [...defaultCurrencies]
            }

            form.setFieldsValue(formBody)
        } else {
            const formValue = form.getFieldsValue();
            form.setFieldsValue({...formValue, payoutPercent: 97, currencies: defaultCurrencies})
        }
    }, [client])


    const onFinish = (data) => {
        if (client.id) {
            editClient({...data, id: client.id, name: client.name})

        } else {
            addClient(data)
        }
    }

    useEffect(() => {
        getCurrencyList()
        if (client.id) {
            subscribeForError(REQUEST_EDIT_CLIENT, () => {
                handleCancel();
            });
        } else {
            subscribeForError(REQUEST_ADD_CLIENT, () => {
                handleCancel();
            });
        }
        return () => {
            unSubscribeForError(REQUEST_ADD_CLIENT);
            unSubscribeForError(REQUEST_EDIT_CLIENT);
        }
    }, [])

    const formValueChange = () => {
        let firstTabValid = false;
        let secondTabValid = false;
        setFirstTabError(false);
        setSecondTabValid(false);


        form.getFieldsError().some((field) => {
            if (field.name.includes("currencies")) {
                secondTabValid = field.errors.length > 0
                return field.errors.length > 0
            } else {
                firstTabValid = field.errors.length > 0
                return field.errors.length > 0
            }
        })
        setFirstTabError(firstTabValid)
        setSecondTabValid(secondTabValid)
    }

    useEffect(() => {
        if (isFirstOpen) {
            return
        }
        const values = form.getFieldsValue();
        form.validateFields([...Object.keys(values)], {force: true}).then(() => formValueChange())
    }, [tabMode])


    const enterValidDecimalNumber = (value, name) => {
        const numberRegExp = new RegExp(/^([0-9]+(\.?[0-9]?[0-9]?)?)$/g);
        let formValue = form.getFieldsValue()
        if (numberRegExp.test(value)) {
            formValue[name[0]][name[1]][name[2]] = value
            const afterDot = value.split(".")[0]
            const beforeDot = value.split(".")[1]
            if (/\b(0)+[1-9]\d*/g.test(afterDot)) {
                const val = afterDot.replace(/\b(0)+/g, '')
                formValue[name[0]][name[1]][name[2]] = val
            } else {
                if (/\b(0)+[.]\d*/g.test(value)) {
                    const val = afterDot.replace(/\b(0)+/g, '') + "." + beforeDot;
                    formValue[name[0]][name[1]][name[2]] = "0" + val
                }
            }
        } else {
            formValue[name[0]][name[1]][name[2]] = value.substring(0, value.length - 1)
        }

        form.setFieldsValue(formValue)
    }

    return (
        <>
            {

                <Modal
                    title={client.id ? <FormattedMessage id='edit_client'/> : <FormattedMessage id='add_client'/>}
                    centered
                    className={classNames(`formModal`, {loading: loading._single_client})}
                    visible={show}
                    width={596}
                    onCancel={() => handleCancel()}
                    footer={false}>
                    {
                        loading._single_client ?
                            <ModalLoader tabs={true}/> :
                            <>
                                <div className="tabMode centeredBox">
                                    <Radio.Group onChange={handleModeChange} value={tabMode}
                                                 className={classNames({invalid: firstTabError || secondTabValid})}>
                                        <Radio.Button className={classNames("centeredBox", {invalid: firstTabError})}
                                                      value="1"><FormattedMessage
                                            id={'details'}/></Radio.Button>
                                        <Radio.Button className={classNames("centeredBox", {invalid: secondTabValid})}
                                                      value="2"><FormattedMessage
                                            id={'currency'}/></Radio.Button>
                                    </Radio.Group>
                                </div>
                                <Form
                                    layout="vertical"
                                    form={form}
                                    name="trading-form"
                                    onFieldsChange={formValueChange}
                                    onFinish={onFinish}
                                    scrollToFirstError
                                    validateTrigger="onBlur"
                                    wrapperCol={false}
                                >
                                    <Tabs activeKey={tabMode} className="overflowContent">
                                        <Tabs.TabPane key="1" forceRender={true}>
                                            <Row className="MainModal overflowContent">
                                                <Col span={24} className="MainModal--parts">
                                                    <div className="MainModal--parts--content">
                                                        {client.id ?
                                                            <Form.Item label={<FormattedMessage id='name'/>}>
                                                                {client.name}
                                                            </Form.Item>
                                                            :
                                                            <Form.Item label={<FormattedMessage id='name'/>}
                                                                       rules={[
                                                                           {
                                                                               required: true,
                                                                               message: <FormattedMessage
                                                                                   id='name_validator'/>
                                                                           },
                                                                           ({getFieldValue}) => ({
                                                                               validator(_, value) {

                                                                                   if (value && !(value.length >= 3 && value.length <= 256)) {
                                                                                       return Promise.reject(intl.formatMessage({id: `name_valid`}))
                                                                                   }

                                                                                   return Promise.resolve();
                                                                               },
                                                                           })
                                                                       ]}
                                                                       name="name">
                                                                <Input autoComplete="off"
                                                                       placeholder={intl.formatMessage({id: "type_name"})}/>
                                                            </Form.Item>
                                                        }
                                                    </div>
                                                    <div className="MainModal--parts--content">
                                                        <Form.Item label={<FormattedMessage id='secret'/>}
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage
                                                                               id='secret_validator'/>
                                                                       },
                                                                       ({getFieldValue}) => ({
                                                                           validator(_, value) {

                                                                               if (value && value.length > 255) {
                                                                                   return Promise.reject(intl.formatMessage({id: `client_secret_valid`}))

                                                                               }
                                                                               return Promise.resolve();
                                                                           },
                                                                       })
                                                                   ]}
                                                                   name={"secret"}
                                                        >
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "type_secret"})}/>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="MainModal--parts--content">
                                                        <Form.Item label={<FormattedMessage id='bet_url'/>}
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage
                                                                               id='bet_url_validator'/>
                                                                       },
                                                                       ({getFieldValue}) => ({
                                                                           validator(_, value) {
                                                                               const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                               if (value && !urlPattern.test(value)) {
                                                                                   return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                               }
                                                                               return Promise.resolve();
                                                                           },
                                                                       })
                                                                   ]}
                                                                   name={"betCallback"}
                                                        >
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "type_bet_url"})}/>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="MainModal--parts--content">
                                                        <Form.Item label={<FormattedMessage id='result_url'/>}
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage
                                                                               id='result_url_validator'/>
                                                                       },
                                                                       ({getFieldValue}) => ({
                                                                           validator(_, value) {
                                                                               const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                               if (value && !urlPattern.test(value)) {
                                                                                   return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                               }
                                                                               return Promise.resolve();
                                                                           },
                                                                       })
                                                                   ]}
                                                                   name={"resultCallback"}
                                                        >
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "type_result_url"})}/>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="MainModal--parts--content">
                                                        <Form.Item label={<FormattedMessage id='rollback_url'/>}
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage
                                                                               id='rollback_url_validator'/>
                                                                       },
                                                                       ({getFieldValue}) => ({
                                                                           validator(_, value) {
                                                                               const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                               if (value && !urlPattern.test(value)) {
                                                                                   return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                               }
                                                                               return Promise.resolve();
                                                                           },
                                                                       })
                                                                   ]}
                                                                   name={"refundCallback"}
                                                        >
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "type_rollback_url"})}/>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="MainModal--parts--content">
                                                        <Form.Item label={<FormattedMessage id='game_url'/>}
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage
                                                                               id='game_url_validator'/>
                                                                       },
                                                                       ({getFieldValue}) => ({
                                                                           validator(_, value) {
                                                                               const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                               if (value && !urlPattern.test(value)) {
                                                                                   return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                               }
                                                                               return Promise.resolve();
                                                                           },
                                                                       })
                                                                   ]}
                                                                   name={"gameUrl"}
                                                        >
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "type_game_url"})}/>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="MainModal--parts--content">
                                                        <Form.Item label={<FormattedMessage id='balance_url'/>}
                                                                   rules={[
                                                                       {
                                                                           required: true,
                                                                           message: <FormattedMessage
                                                                               id='balance_url_validator'/>
                                                                       },
                                                                       ({getFieldValue}) => ({
                                                                           validator(_, value) {
                                                                               const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                               if (value && !urlPattern.test(value)) {
                                                                                   return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                               }
                                                                               return Promise.resolve();
                                                                           },
                                                                       })
                                                                   ]}
                                                                   name={"balanceCallback"}
                                                        >
                                                            <Input autoComplete="off"
                                                                   placeholder={intl.formatMessage({id: "type_balance_url"})}/>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="MainModal--parts--content">
                                                        <Form.Item
                                                            label={<FormattedMessage id='is_under_maintenance'/>}
                                                            className="formHorizontal"
                                                            name="isUnderMaintenance"
                                                            valuePropName="checked">
                                                            <Switch className="radioSwitch"/>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="MainModal--parts--content">
                                                        <Form.Item
                                                            label={<FormattedMessage id='win_ration'/>}
                                                            name="payoutPercent"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: <FormattedMessage
                                                                        id='payoutPercent_is_mandatory'/>
                                                                }]}>
                                                            <Radio.Group>
                                                                <Space direction="horizontal">
                                                                    <Radio value={93}>93</Radio>
                                                                    <Radio value={95}>95</Radio>
                                                                    <Radio value={97}>97</Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tabs.TabPane>
                                        <Tabs.TabPane key="2" forceRender={true}>
                                            <Form.List name="currencies">
                                                {(fields, {add, remove}) => (
                                                    <>
                                                    <Infinite containerHeight={400}
                                                              elementHeight={fields.map((_, index) => index === 0 ? 75 : 48)}>
                                                        {fields.map(({key, name, fieldKey, ...restField}) =>
                                                            <Space key={key} className={`defaultGames ${name === 0 ? "firstChild" : ""}`} size={0}>
                                                                <Form.Item
                                                                    label={name === 0 ? <FormattedMessage id='currency_name'/> : undefined }
                                                                    {...restField}
                                                                    name={[name, 'currencyId']}
                                                                    fieldKey={[fieldKey, 'currencyId']}
                                                                    rules={
                                                                        [
                                                                            {
                                                                                required: true,
                                                                                message: <FormattedMessage
                                                                                    id='currency_is_required'/>
                                                                            }
                                                                        ]
                                                                    }
                                                                >
                                                                    <Select placeholder={intl.formatMessage({id: "select"})}
                                                                            optionLabelProp="label"
                                                                            dropdownMatchSelectWidth={false}
                                                                            className="gameTemplateCurrencySelect"
                                                                            getPopupContainer={triggerNode => triggerNode.parentElement}>
                                                                        {
                                                                            currencyList.map(currency => (
                                                                                <Select.Option value={currency.id}
                                                                                               key={currency.id}
                                                                                               label={
                                                                                                   <>
                                                                                                       <img
                                                                                                           src={currency.icon}
                                                                                                           className="icon"/>
                                                                                                       {
                                                                                                           currency.code}
                                                                                                   </>
                                                                                               }>
                                                                                    <img
                                                                                        src={currency.icon}
                                                                                        className="icon"/>
                                                                                    {currency.description}
                                                                                </Select.Option>
                                                                            ))
                                                                        }

                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    {...restField}
                                                                    label={name === 0 ? <FormattedMessage id='min_stake'/> : undefined }
                                                                    name={[name, 'minStake']}
                                                                    fieldKey={[fieldKey, 'minStake']}
                                                                    onBlur={() => {
                                                                        form.validateFields([["currencies", name, 'maxStake']])
                                                                    }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: <FormattedMessage
                                                                                id='min_stack_required'/>
                                                                        },
                                                                        ({getFieldValue}) => ({
                                                                            validator(_, value) {

                                                                                if (value && value <= 0) {
                                                                                    return Promise.reject(intl.formatMessage({id: `stake_validation`}));

                                                                                }

                                                                                if (!value || Number(getFieldValue(["currencies", name, 'maxStake'])) > Number(value) || !getFieldValue(["currencies", name, 'maxStake'])) {
                                                                                    return Promise.resolve();
                                                                                }

                                                                                return Promise.reject(intl.formatMessage({id: `the_min_value_cant_be_larger_the_max`}));
                                                                            },
                                                                        })
                                                                    ]}
                                                                >
                                                                    <Input autoComplete="off"
                                                                           onChange={({target}) => enterValidDecimalNumber(target.value, ["currencies", name, 'minStake'])}
                                                                           placeholder={intl.formatMessage({id: `type_stake`})}/>
                                                                </Form.Item>
                                                                <div className="separator"/>
                                                                <Form.Item
                                                                    label={name === 0 ? <FormattedMessage id='max_stake'/> : undefined}
                                                                    {...restField}
                                                                    name={[name, 'maxStake']}
                                                                    fieldKey={[fieldKey, 'maxStake']}
                                                                    onBlur={() => {
                                                                        form.validateFields([["currencies", name, 'minStake']])
                                                                    }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: <FormattedMessage
                                                                                id='max_stack_required'/>
                                                                        },
                                                                        ({getFieldValue}) => ({
                                                                            validator(_, value) {
                                                                                if (value && value > 2000000000) {
                                                                                    return Promise.reject(intl.formatMessage({id: `stake_validation`}));

                                                                                }

                                                                                if (!value || Number(getFieldValue(["currencies", name, 'minStake'])) < Number(value) || !getFieldValue(["currencies", name, 'minStake'])) {
                                                                                    return Promise.resolve();
                                                                                }

                                                                                return Promise.reject(intl.formatMessage({id: `the_max_value_cant_be_smaller_the_min`}));
                                                                            },
                                                                        })
                                                                    ]}>
                                                                    <Input autoComplete="off"
                                                                           onChange={({target}) =>  enterValidDecimalNumber(target.value, ["currencies", name, 'maxStake'])}
                                                                           placeholder={intl.formatMessage({id: `type_stake`})}/>
                                                                </Form.Item>

                                                                <MinusOutlined  className={classNames("iconBox ", {disabled: fields.length === 1})}
                                                                               onClick={() => {
                                                                                   if(fields.length !== 1) {
                                                                                       remove(name)
                                                                                       setTimeout(() =>formValueChange(), 10)
                                                                                   }
                                                                               }}
                                                                               disabled={fields.length === 1}/>
                                                            </Space>
                                                        )}
                                                    </Infinite>
                                                        <Form.Item className="add-button">
                                                            <Tooltip placement="left" title={<FormattedMessage id="add_currency"/>}>
                                                                <PlusOutlined
                                                                    onClick={() => add()}/>
                                                            </Tooltip>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Tabs.TabPane>
                                    </Tabs>
                                    <Form.Item className="MainModal--footer">
                                        <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                            <FormattedMessage id='cancel'/>
                                        </button>
                                        <Button type="primary"
                                                htmlType="submit"
                                                style={{height: 36}}
                                                className={classNames("miniBtn", {submit: firstTabError || secondTabValid})}
                                                loading={loading._add_client || loading._edit_client}
                                        >
                                            <FormattedMessage id='save'/>
                                        </Button>
                                        <div className="error_box"><FormattedMessage id="form_error"/></div>
                                    </Form.Item>
                                </Form>
                            </>
                    }
                </Modal>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        currencyList: state.currencyList
    }
}

const mapDispatchToProps = {
    addClient,
    editClient,
    getCurrencyList
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ClientControlPopup));