import {call, put, takeLatest} from "redux-saga/effects";
import {GetResource} from "../../utils/HttpUtil";
import {
    REQUEST_CURRENCY_LIST,
    SET_CURRENCY_LIST
} from "@actions/actionTypes";
import {onError, safe} from "./handlerError";


function* getCurrencyList() {
    const currencyList =  yield call(GetResource, `/client/currencies`);
    yield put({
        type: SET_CURRENCY_LIST,
        currencyList: currencyList && currencyList.items ? currencyList.items : []
    });
}

function* CurrencySaga() {
    yield takeLatest(REQUEST_CURRENCY_LIST, safe(onError, getCurrencyList));
}

export default CurrencySaga;